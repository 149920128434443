import { customDataRouteObject, customRoute } from "../dataTypes/ReactRouter";
// TOP LEVEL PAGES
import IndexPage from "../pages";

import AuthPage from "../pages/auth";

import InvitationPage from "../pages/invitations/invitation";
import FirebaseActionPage from "../pages/firebase-action";
import GeneralInfoPage from "../pages/general-info/generailInfo";

import NotFoundPage from "../pages/404";
import ResetPasswordForm from "../components/auth/ResetPassword";
import AuthActionTemplate from "../pages/auth-action-templates/auth-action";

import SeekerScheduleInterviewPage from "../components/live/pages/SeekerScheduleInterview";
import JoinLiveMeetingPage from "../components/live/pages/JoinLiveMeeting";

import SocialPage from "../pages/social/SocialPage";
import SeekerRegistrationPage from "../pages/seekerPages/SeekerRegistrationPage";
import SeekerRegistrationIndex from "../components/seeker/details/registration/SeekerRegistrationIndex";

import { ErrorBoundary } from "react-error-boundary";
import Fallback from "../components/fallback/Fallback";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { useEffect, useState } from "react";

export const primaryPublicChildren: customDataRouteObject[] = [
  {
    id: "Auth",
    name: "Auth",
    path: "/auth/:type",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <AuthPage />,
      </ErrorBoundary>
    ),
  },
  {
    id: "Firebase Actions",
    name: "Firebase Actions",
    path: "/firebase-action",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <FirebaseActionPage />,
      </ErrorBoundary>
    ),
  },
  {
    id: "General Info",
    name: "General Info",
    path: "/generalInfo",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <GeneralInfoPage />,
      </ErrorBoundary>
    ),
  },

  {
    id: "Invitations",
    name: "Invitations",
    path: "/invitation/:invitationId",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <InvitationPage />,
      </ErrorBoundary>
    ),
  },

  {
    id: "Schedule Interview",
    name: "Schedule Interview",
    path: "/schedule-meeting/:meetingId",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <SeekerScheduleInterviewPage />,
      </ErrorBoundary>
    ),
  },
  {
    id: "Manage Interview",
    name: "Manage Interview",
    path: "/manage-meeting/:meetingId/:manageMeeting",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <SeekerScheduleInterviewPage />,
      </ErrorBoundary>
    ),
  },
  {
    id: "Join Meeting",
    name: "Join Meeting",
    path: "/join-meeting/:meetingId",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <JoinLiveMeetingPage />
      </ErrorBoundary>
    ),
  },

  {
    id: "Reset Password",
    name: "Reset Password",
    path: "/resetpassword/:resetId",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <ResetPasswordForm />,
      </ErrorBoundary>
    ),
  },
  {
    id: "Email Verified",
    name: "Email Verified",
    path: "/authaction",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <AuthActionTemplate />,
      </ErrorBoundary>
    ),
  },
  {
    id: "social",
    name: "Social Share",
    path: "/social/:shareId",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <SocialPage />,
      </ErrorBoundary>
    ),
  },
  {
    id: "SeekerRegister",
    name: "Seeker Registration",
    path: "/seeker/create-account",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <SeekerRegistrationPage />,
      </ErrorBoundary>
    ),
    errorElement: <NotFoundPage />,
    children: [],
  },
];

export const publicRoute: customRoute[] = [
  {
    id: "Index",
    name: "Index",
    path: "/",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <IndexPage />
      </ErrorBoundary>
    ),
    errorElement: <NotFoundPage />,
    children: primaryPublicChildren,
  },
];

export const CreatePublicRoutes = () => { 

  const [routes, setRoutes] = useState(createBrowserRouter(publicRoute));
  useEffect(()=>{
    setRoutes(createBrowserRouter(publicRoute))
  },[])
  return <RouterProvider router={routes} />;

}
