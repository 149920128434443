import React, { useState, useEffect } from "react";
import { updateAutoSave, logActivity } from "../../../data/utility";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { useUsersByRole } from "../../../data/user";
import { useAuth } from "../../../providers/auth";

const AutoSaveSelectGeneric = ({
  value: initialValue,
  options = [],
  optionValue = "code", // used to set the key used to get the value on each option
  collection,
  recId,
  required,
  name,
  display,
  placeholder,
  directions,
  disabled = false,
}) => {
  const auth = useAuth();
  const [value, setValue] = useState(initialValue?initialValue:options[0]?.id);
  const [check, setCheck] = useState(initialValue);
  const [isValid, setIsValid] = useState(true);
  const [saved, setSaved] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setSaved(false);
    }, 3000);
  }, [saved]);

  useEffect(() => {
    console.log("auto-save-select-generic, useEffect, value: ", value);
  }, [value]);

  // if there is only one location available (From options)
  // set that as the value
  useEffect(() => {
    console.log(
      "auto-save-select-generic, useEffect, initialValue: ",
      initialValue,
    );
  }, []);

  const handleChange = (event) => {
    setValue(event.target.value);
    event.preventDefault();
  };

  const handleBlur = async () => {
    if (!value && required) {
      setIsValid(false);
      return;
    }
    if (value ) {
      setIsValid(true);
      setCheck(value);
      await updateAutoSave(collection, recId, { [name]: value });
      await logActivity(
        collection,
        recId,
        name,
        check,
        value,
        auth.user.uid,
        check === "" ? "Added" : "Edited",
      );
      setSaved(true);
    }
  };

  return (
    <div id={name} className="relative">
      <label className="text-instant-teams-teal-Main text-sm  absolute  duration-300 transform -translate-y-4 scale-75 top-4 origin-[0] left-2.5 peer-focus:text-instant-teams-blue-Main peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4">
        {display} {directions ? " - " + directions : null}
      </label>
      <select
        disabled={disabled}
        name={name}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        className="form-input shadow-md rounded-md w-full pt-3"
      >
        {/* <option disabled={true} value="" selected={true}>
          {placeholder}
        </option> */}
        <option disabled={true} value="" defaultValue={""}>
          {placeholder}
        </option>
        {Object.keys(options).length ? (
          options.map((item) => {
            return (
              <option key={item.id} value={item[optionValue]}>
                {item.label}
              </option>
            );
          })
        ) : (
          <option disabled={true}>N/A</option>
        )}
      </select>
      {saved && (
        <span className="absolute right-4 top-2 text-xs">
          <FontAwesomeIcon icon={faCircleCheck} style={{ color: "green" }} />
        </span>
      )}
      {!isValid && <div className="error">This field is required.</div>}
    </div>
  );
};

export default AutoSaveSelectGeneric;
