import { useEffect, useState, useRef } from "react";
import { createMeetingInvitation } from "../../data/meetings";
import { cancelMeeting } from "../../data/meetings";
import { useAuth } from "../../providers/auth";
import { useUser, updateUser } from "../../data/user";
import ScheduleDatePicker from "./ScheduleDatePicker";
import { useLookup } from "../../data/lookup";
import { useEmployerUsers } from "../../data/employer";

function ScheduleMeetingOneOnOne({
  refType,
  refId,
  title,
  extended,
  inviteeId,
  isReschedule = false,
  onSuccess = () => { },
  existingMeetingId = null,
}: {
  refType: string;
  refId: string;
  title: string;
  inviteeId: string;
  isReschedule?: boolean;
  existingMeetingId?: string | null;
  extended: any;
  onSuccess: Function;
}) {
  const auth = useAuth();
  const userCalendlySetting = auth?.user?.calendly;
  const hasCalendlySetting = !!userCalendlySetting;

  const isInternalUser = auth?.user?.claims?.employerId === "000";

  // get invitee information
  const { data: inviteeData, error: seekerError } = useUser(inviteeId);

  const { data: meetingCodes = [] } = useLookup(refType);
  const [meetingCode, setMeetingCode] = useState("make-selection");

  // scheduleInApp is used to display the alternative options for scheduling an interview
  const [scheduleInApp, setScheduleInApp] = useState(false);
  const toggleScheduleInApp = () => {
    setScheduleInApp((prevState: boolean) => !prevState);
    // clear error message
    setErrorMessage();
  };


  const [dateTimeOptions, setDateTimeOptions] = useState();
  const [meetingDuration, setMeetingDuration] = useState();

  const handleOptionsSelection = ({
    dateTimeOptions,
    meetingDuration,
  }: {
    dateTimeOptions: any;
    meetingDuration: number;
  }) => {
    setDateTimeOptions(dateTimeOptions);
    setMeetingDuration(meetingDuration);
  };

  const [calendlyLink, setCalendlyLink] = useState("");
  const [errorMessage, setErrorMessage] = useState();
  const [interviewParticipants, setInterviewParticipants] = useState([])
  const [interPartciMail, setInterPartciMail] = useState()
  const [particiMailSuggestion, setParticiMailSuggestion] = useState([])


  const {
    data: employees = [],
    status: employeesStatus,
    error: employeesError,
  } = useEmployerUsers(extended?.employerId);

  console.log("employees", employees)


  useEffect(() => {
    if (scheduleInApp) return;
    // if scheduleInApp if false reset date time options
    setDateTimeOptions();
    setMeetingDuration();
  }, [scheduleInApp]);

  // if calenddly link is available in user settings then setCalendlyLink
  useEffect(() => {
    if (userCalendlySetting) {
      setCalendlyLink(userCalendlySetting);
    }
  }, [userCalendlySetting]);

  // if external user then set scheduleInApp
  useEffect(() => {
    if (!isInternalUser) {
      setScheduleInApp(true);
    }
  }, [isInternalUser]);

  const calendlyInputRef = useRef(null);

  const isCalendlyLinkValid = (link: string) => {
    // Matches the beginning of url https://calendly.com/
    const calendlyRegex = /^https:\/\/calendly\.com\/.*/;
    if (calendlyRegex.test(link)) {
      return true;
    }
    return false;
  };

  const hasDuplicateDateTimes = (dates: any) => {
    const dateSet = new Set(dates.map((date: any) => date.getTime()));
    return dateSet.size !== dates.length;
  };

  const sendMeetingInvitation = () => {
    if (meetingCode === "make-selection" || !meetingCode) {
      setErrorMessage("Please select a meeting type.");
      return;
    }

    if (
      (!scheduleInApp && isCalendlyLinkValid(calendlyLink)) ||
      (scheduleInApp && dateTimeOptions.length > 0)
    ) {
      // update calendly link if the calendly link input is present, the link is valid,
      // and they do not have a calendly link
      // the input will not show if user has calendly link saved to settings.
      if (
        !scheduleInApp &&
        isCalendlyLinkValid(calendlyLink) &&
        calendlyInputRef.current
      ) {
        updateUser(auth?.user?.id, { calendly: calendlyLink })
          .then(() => console.log("updated user settings."))
          .catch((error) =>
            console.error("Problem updating user record", error),
          );
      }
      if (scheduleInApp && hasDuplicateDateTimes(dateTimeOptions)) {
        setErrorMessage("Please select 3 different meeting times.");
        return;
      }

      createMeetingInvitation({
        refType,
        refId,
        title,
        meetingData: {
          extended,
          meetingCode,
          inviteeId,
          inviteeEmail: inviteeData.email,
          invitedBy: auth.user.uid,
          calendlyLink,
          dateTimeOptions,
          meetingDuration,
          status: "pending",
          additionalParticipant: interviewParticipants.map((email: string) => ({ email: email })),
        },
      })
        .then((result) => {
          onSuccess();
        })
        .catch((error) => {
          setErrorMessage(
            "There was a problem creating the meeting invitation.",
          );
        });
      return;
    }
    setErrorMessage("Please enter a valid Calendly link.");
  };

  const removeParticipant = (index: number) => {
    const updatedParticipants = [...interviewParticipants];
    updatedParticipants.splice(index, 1);
    setInterviewParticipants(updatedParticipants);
  };

  const handleParticiInputChange = (e: any) => {
    const inputValue = e.target.value;
    setInterPartciMail(inputValue);
    const filteredSuggestions = employees.filter((data: any) =>
      data?.email?.toLowerCase().startsWith(inputValue.toLowerCase())
    );
    setParticiMailSuggestion(filteredSuggestions);
  }

  const handleParticipantsSuggestion = (email: string) => {
    if (!interviewParticipants.includes(email)) {
      setInterviewParticipants([...interviewParticipants, email]);
      setParticiMailSuggestion('')
    }


    return
  }

  const handleAddparticipants = () => {

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (emailRegex.test(interPartciMail)) {

      if (!interviewParticipants.includes(interPartciMail)) {

        setInterviewParticipants([...interviewParticipants, interPartciMail]);
        setErrorMessage("")
        setInterPartciMail("")
      } else {
        setErrorMessage('Email is already included in the participants list.');
      }
    } else {
      setErrorMessage("Enter Valid Email Address")
    }

  }



  return (
    <div className="m-12">
      <div className="w-full content-center">
        {errorMessage && (
          <div className="text-instant-teams-red-Main">{errorMessage}</div>
        )}
        {/* if scheduler is external user schedule in app with date picker */}
        {!isInternalUser && (
          <div className="mb-2">
            <div className="h-72 ">
              <div className="mb-4 font-bold text-instant-teams-teal-D1">
                Please select three date and time options for scheduling your
                meeting.
              </div>
              <ScheduleDatePicker handleChange={handleOptionsSelection} />
            </div>
          </div>
        )}

        {/* if scheduler is internal user and does not have a calendly link give them the option to
        enter a calendly link or schedule in app with date picker */}
        {!hasCalendlySetting && isInternalUser && (
          <div className="mb-2">
            {scheduleInApp ? (
              <div className="h-72 ">
                <div className="mb-4 font-bold text-instant-teams-teal-D1">
                  Please select three date and time options for scheduling your
                  meeting.
                </div>
                <ScheduleDatePicker handleChange={handleOptionsSelection} />
              </div>
            ) : (
              <div>
                <div className="mb-4 text-instant-teams-teal-D1">
                  Enter your Calendly link below and we will save it to your
                  user settings.
                </div>
                <label className="sr-only" htmlFor="calendlyLink">
                  Calendly Link
                </label>
                <input
                  ref={calendlyInputRef}
                  className="py-1 px-3 w-full leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1"
                  name="calendlyLink"
                  value={calendlyLink || ""}
                  placeholder="Calendly link"
                  onChange={(e) => {
                    setCalendlyLink(e.target.value);
                  }}
                  onFocus={() => setErrorMessage(null)}
                  type="text"
                />
              </div>
            )}
          </div>
        )}
        <div className="flex flex-col items-center">
          {userCalendlySetting && (
            <div className="mb-8 text-instant-teams-teal-D1 font-bold">
              <p>
                We send your Calendly link with the invitation. This can be
                changed in your user profile settings
              </p>
            </div>
          )}
          <div className="mb-4 w-full">
            <label className="text-left sr-only">Select a meeting type:</label>
            <select
              value={meetingCode}
              className="w-full bg-white border border-gray-200"
              onChange={(e) => setMeetingCode(e.target.value)}
            >
              <option value="make-selection" disabled={true}>
                Select a meeting type
              </option>
              {meetingCodes.map((item: any) => (
                <option key={item.id} value={item.code}>
                  {item.label}
                </option>
              ))}
            </select>

            <div className=" w-full bg-white mt-2  "  >
              {
                interviewParticipants && interviewParticipants.map((mailId: string, index: number) => (
                  <div key={index} className="py-0.5 flex items-center  rounded-lg w-full px-1 mb-1 text-sm" onClick={() => removeParticipant(index)}>
                    <p className="mr-2 text-gray-500 ">{mailId}</p>
                    <div className="bg-gray-200 rounded-full p-0.5">
                      <svg className="w-3.5 h-3.5 fill-current text-gray-700 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                      </svg>
                    </div>
                  </div>
                ))
              }
              <input className="w-full rounded-lg bg-white text-[13px] border border-gray-200"
                value={interPartciMail}
                onChange={handleParticiInputChange}
                placeholder="Enter email to add more participants (Optional)"
              >
              </input>
              {
                errorMessage && <p className="text-red-700 text-sm">{errorMessage}</p>
              }
              {particiMailSuggestion && interPartciMail &&
                <div className="w-full  rounded-b-lg bg-white border-b border-rgb-59-130-246 border-opacity-50 focus:shadow-outline">
                  {particiMailSuggestion?.map((item: any) => (
                    <p className="text-[14px] p-1.5 hover:cursor-pointer  " key={item?.id} onClick={() => handleParticipantsSuggestion(item.email)}>
                      {item.email}
                    </p>
                  ))}
                </div>}
              <div className="flex justify-end border-0 ">
                <button
                  className="px-2 py-0.5  mt-2 text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none"
                  onClick={handleAddparticipants}

                >
                  Add Participants
                </button>
              </div>
            </div>
          </div>
          <button
            className="w-full mb-2 min-h-12 h-12 text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none"
            onClick={sendMeetingInvitation}
          >
            Send Meeting Invitation
          </button>
          {!userCalendlySetting && isInternalUser && (
            <button
              className="text-instant-teams-blue-L1 underline background-transparent font-bold uppercase px-3 py-1 text-xs outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              onClick={toggleScheduleInApp}
            >
              {scheduleInApp
                ? "Use my Calendly Link"
                : "I dont have a Calendly Link"}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
export default ScheduleMeetingOneOnOne;
