import React from "react";
import { Transition, Dialog } from "@headlessui/react";
import { useAuth } from "../../../providers/auth";

function DialogWrapper({
  children,
  title,
  size = "max-w-md",
  onClose,
  titleColor = "bg-instant-teams-teal-Main",
  bgColor = "bg-white"
}: {
  children: JSX.Element;
  title: string;
  size?: string;
  titleColor?: string;
  bgColor?: string;
  onClose: () => void;
}) {
  const auth = useAuth();
  const user = auth?.user;
  console.log(titleColor);
  return (
    <>
      <Transition appear={true} show={true}>
        <Dialog
          onClose={onClose}
          className="overflow-y-auto fixed inset-0 z-10"
        
        >
          <div className="px-4 min-h-screen text-center" style={{zIndex:10}}>
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75" />
            </Transition.Child>
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div
                className={`${size} inline-block overflow-hidden my-8 w-full text-left align-middle ${bgColor} rounded-2xl shadow-xl transition-all transform`}
              >
                <Dialog.Title className={`text-lg font-medium leading-6 p-2 pl-8 ${titleColor} text-white w-full`}>
                  {title}
                </Dialog.Title>
                <div className="p-6">{children}</div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default DialogWrapper;
